import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import Slider from "../components/slider/slider-marcacao"
import SliderMobile from "../components/slider/slider-marcacaoMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const MarcacaoPage = ({ data }) => (
  <>
    <SEO title="Marcação de Assistência" image={data.marcacaoJson.background.childImageSharp.fluid.src} />
    <Layout>
      {useBreakpoint().mobile ? (
        <>
          <HeaderMobile data={data.globalJson.header} />
          <SliderMobile data={data.marcacaoJson} />
          <FooterMobile data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
        </>
      ) : (
        <>
          <Header data={data.globalJson.header} />
          <Slider data={data.marcacaoJson} />
          <Footer data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
        </>
      )}
    </Layout>
  </>
)

export default MarcacaoPage

export const Json = graphql`
  query marcacao {
    marcacaoJson {
      background {
        childImageSharp {
          fluid(quality: 100, maxWidth: 3400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backgroundM {
        childImageSharp {
          fluid(quality: 100, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      title
      titleM
      page1 {
        indicator
        brand
        model
        plate
        year
        mileage
        btnText
        btnLink
        btnClass
      }
      page2 {
        indicator
        date
        time
        time1
        time2
        btnText
        btnLink
        btnClass
      }
      page3 {
        indicator
        name
        address
        phone
        email 
        btnText
        btnLink
        btnClass
      }
      page4 {
        btnText
        btnLink
        btnClass
      }
      success {
        subtitle
        text
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
